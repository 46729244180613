import { BaseService, T4BaseService } from './BaseService'

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    console.log('fetchDataError:', errors)
                    reject(errors)
                })
        })
    },
    executeQuery(param) {
        return new Promise((resolve, reject) => {
            T4BaseService(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    console.log('fetchDataError:', errors)
                    reject(errors)
                })
        })
    },

    async getQuery(route = '', useFullRoute = null)  {
        try {
            const response = await fetch(useFullRoute ?? `https://sentry.io/api/0/projects/t4isb/${route}`, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer 89f2351d6729bcdc84c9f57aea666d69dfa139aa1584192d54017cd811250240',
                    'Content-type': 'application/json',
                })
            });
            const data = await response.json()
            return { success: true, data };
        } catch (error) {
            console.log('Error:', error)
            return { success: false, status: 'Error on Authentication', code: error.code, message: error.message };
        }
    },
}

export default ApiService
